<template>
  <div>
    <v-row>
      <v-col class="py-0">
        <v-breadcrumbs class="pa-5" :items="breadcrumbs"></v-breadcrumbs>
      </v-col>
    </v-row>

    <!-- Lista de solicitacoes -->
    <v-row>
      <v-col :class="{ shorten: $vuetify.breakpoint.lgAndUp }">
        <v-data-table
          :headers="grid.headers"
          :items="filteredItems"
          :loading="grid.loading"
          loading-text="Carregando... aguarde"
          locale="pt"
          class="elevation-1"
        >
          <template v-slot:item.codigoMec="{ item }">
            <span>{{ item.codigoMec }}</span>
          </template>

          <template v-slot:item.cnpjUex="{ item }">
            <span>{{ item.cnpjUex }}</span>
          </template>

          <template v-slot:item.status="{ item }">
            <span>{{ item.status }}</span>
          </template>

          <template v-slot:item.dataEnvio="{ item }">
            <span>{{ item.dataEnvio }}</span>
          </template>

          <template v-slot:item.valorRepasse="{ item }">
            <span>{{ item.valorRepasse | toCurrency }}</span>
          </template>

          <template v-slot:item.valorCusteio="{ item }">
            <span>{{ item.valorCusteio | toCurrency }}</span>
          </template>

          <template v-slot:item.valorCapital="{ item }">
            <span>{{ item.valorCapital | toCurrency }}</span>
          </template>

          <template v-slot:item.actions="{ item }">
            <div>
              <v-icon
                color="blue"
                class="mr-3"
                @click="visualizarSolicitacao(item)"
              >
                mdi-eye-outline
              </v-icon>

              <v-icon color="black" @click="downloadComprovante(item)">
                mdi-download
              </v-icon>
            </div>
          </template>
        </v-data-table>

        <v-navigation-drawer
          v-if="$vuetify.breakpoint.lgAndUp || mobileFilter"
          v-model="mobileFilter"
          :permanent="$vuetify.breakpoint.lgAndUp"
          :absolute="$vuetify.breakpoint.lgAndUp"
          :fixed="$vuetify.breakpoint.mdAndDown"
          right
          class="elevation-1"
        >
          <v-card elevation="0" class="pa-4">
            <v-card-title class="grey--text text--darken-2"
              >Filtros</v-card-title
            >
            <v-text-field
              v-model="grid.filters.cnpjUex"
              label="CNPJ Uex"
              dense
              solo
            ></v-text-field>

            <v-card-actions class="d-flex justify-end">
              <v-btn color="primary" text @click="filter"> Filtrar </v-btn>
            </v-card-actions>
          </v-card>
        </v-navigation-drawer>
      </v-col>
    </v-row>
    <!-- Fim Lista de solicitacoes -->

    <!-- Dialogo visualizar solicitacao -->
    <v-row justify="center">
      <v-dialog
        v-model="dialogoVisualizarSolicitacao"
        persistent
        max-width="100%"
      >
        <v-card>
          <v-card-title class="d-flex justify-space-between mb-6">
            <span class="text-h5">Dados da solicitação</span>
            <div>
              <v-icon class="mr-3" @click="imprimirPdfSolicitacao()">
                mdi-printer
              </v-icon>
              <v-icon @click="fecharDialogoVisualizarSolicitacao()">
                mdi-arrow-left-thick
              </v-icon>
            </div>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" class="pa-0">
                  <h2 class="text-center">
                    RECURSOS DE CUSTEIO (Material de Consumo)
                  </h2>
                  <v-data-table
                    :headers="gridDetalharSolicitacao.headers"
                    :items="solicitacao.recursoCusteio.materiais"
                    :footer-props="{ 'items-per-page-options': [3, 5, -1] }"
                    :items-per-page="3"
                    no-data-text="Adicione itens"
                  >
                    <template v-slot:item.tipoMaterial="{ item }">
                      <span>{{ item.tipoMaterial.materialNome }}</span>
                    </template>

                    <template v-slot:item.material="{ item }">
                      <span>{{ item.nome }}</span>
                    </template>

                    <template v-slot:item.quantidade="{ item }">
                      <span>{{ item.quantidade }}</span>
                    </template>

                    <template v-slot:item.valorUnitario="{ item }">
                      <span>{{ item.valorUnitario | toCurrency }}</span>
                    </template>

                    <template v-slot:item.valorTotal="{ item }">
                      <span>{{ item.valorTotal | toCurrency }}</span>
                    </template>
                    <template v-slot:item.actions="{ item }">
                      <div>
                        <v-icon
                          color="green"
                          class="mr-3"
                          v-if="verificarSePodeAprovarESalvarSolicitacao"
                          @click="parecerRecursoItem(item, 'custeio')"
                        >
                          mdi-pencil
                        </v-icon>
                      </div>
                    </template>
                    <template v-slot:item.status="{ item }">
                      <v-icon
                        v-if="item.status !== ''"
                        :color="definirCorIconStatusItem(item)"
                        class="mr-3"
                      >
                        {{ definirIconStatusItem(item) }}
                      </v-icon>
                      <span v-else>{{ item.status }}</span>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" class="pa-0">
                  <h2 class="text-center">
                    RECURSOS DE CAPITAL (Material Permanente)
                  </h2>
                  <v-data-table
                    :headers="gridDetalharSolicitacao.headers"
                    :items="solicitacao.recursoCapital.materiais"
                    :footer-props="{ 'items-per-page-options': [3, 5, -1] }"
                    :items-per-page="3"
                    no-data-text="Adicione itens"
                  >
                    <template v-slot:item.materialNome="{ item }">
                      <span>{{ item.materialNome }}</span>
                    </template>

                    <template v-slot:item.itemNome="{ item }">
                      <span>{{ item.itemNome }}</span>
                    </template>

                    <template v-slot:item.quantidade="{ item }">
                      <span>{{ item.quantidade }}</span>
                    </template>

                    <template v-slot:item.valorUnitario="{ item }">
                      <span>{{ item.valorUnitario | toCurrency }}</span>
                    </template>

                    <template v-slot:item.valorTotal="{ item }">
                      <span>{{ item.valorTotal | toCurrency }}</span>
                    </template>
                    <template v-slot:item.actions="{ item }">
                      <div>
                        <v-icon
                          color="orange"
                          class="mr-3"
                          v-if="verificarSePodeAprovarESalvarSolicitacao"
                          @click="parecerRecursoItem(item, 'capital')"
                        >
                          mdi-pencil
                        </v-icon>
                      </div>
                    </template>
                    <template v-slot:item.status="{ item }">
                      <v-icon
                        v-if="item.status !== ''"
                        :color="definirCorIconStatusItem(item)"
                        class="mr-3"
                      >
                        {{ definirIconStatusItem(item) }}
                      </v-icon>
                      <span v-else>{{ item.status }}</span>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="fecharDialogoVisualizarSolicitacao()"
            >
              Fechar
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              v-if="verificarSePodeAprovarESalvarSolicitacao"
              @click="retornarSolicitacaoEscola()"
            >
              Salvar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <!-- Fim Dialogo visualizar solicitacao -->

    <!-- Dialogo parecer da gre -->
    <v-row justify="center">
      <v-dialog
        v-model="dialogoParecerDoGerenteRegional"
        persistent
        max-width="500px"
      >
        <v-card>
          <v-card-title class="d-flex justify-space-between mb-6">
            <span class="text-h5">Parecer do Gerente Regional</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-radio-group v-model="recursoItem.parecer" row>
                  <v-radio label="Aprovado" :value="true"></v-radio>
                  <v-radio label="Negado" :value="false"></v-radio>
                </v-radio-group>
              </v-row>
              <v-row>
                <v-text-field
                  label="Justificativa"
                  v-model="recursoItem.justificativa"
                >
                </v-text-field>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="fecharDialogoParecerDaGre()"
            >
              Fechar
            </v-btn>
            <v-btn color="blue darken-1" text @click="salvarParecerDaGre()">
              Salvar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <!-- Fim Dialogo parecer da gre -->

    <div v-show="false">
      <comprovante
        ref="comprovante"
        :tituloDocumento="tituloPdf"
        :solicitacao="solicitacao"
        :valorTotalEmCapital="valorTotalAplicadoEmCapital"
        :valorTotalEmCusteio="valorTotalAplicadoEmCusteio"
      ></comprovante>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import configurations from "~/commons/configurations";
import notificationMixin from "mixins/notification.mixin";
import pageLoadingMixin from "mixins/page-loading.mixin";
import { AUTH_NAMESPACE, USER, MENU } from "store/modules/auth";
import Comprovante from "./solicitacao-pdf.vue";

const { mapGetters: postLoginGetters } = createNamespacedHelpers(
  AUTH_NAMESPACE
);

export default {
  name: "ManagePafGre",
  mixins: [pageLoadingMixin, notificationMixin],
  components: { Comprovante },
  data: () => ({
    breadcrumbs: [
      { text: "Início", to: "/admin" },
      { text: "Paf-Gre", to: "/admin/paf-gre" },
    ],
    dialogoVisualizarSolicitacao: false,
    dialogoParecerDoGerenteRegional: false,
    indiceRecursoItemEmEdicao: -1,
    colorIconeItemRecurso: "",
    mobileFilter: null,
    grid: {
      loading: false,
      filtered: false,
      filters: {
        cnpjUex: "",
      },
      headers: [
        { text: "Id", value: "solicitacaoId", align: " d-none" },
        { text: "Código Mec", value: "codigoMec" },
        { text: "Cnpj Uex", value: "cnpjUex" },
        { text: "Status", value: "status" },
        { text: "Data de envio", value: "dataEnvio" },
        { text: "Valor Repasse", value: "valorRepasse" },
        { text: "Custeio", value: "valorCusteio" },
        { text: "Capital", value: "valorCapital" },
        { text: "Ações", value: "actions", align: "center", sortable: false },
      ],
      data: [],
    },
    gridDetalharSolicitacao: {
      headers: [
        { text: "Tipo de Material", value: "materialNome" },
        { text: "Item", value: "itemNome" },
        { text: "Quantidade", value: "quantidade" },
        { text: "Valor Unitário", value: "valorUnitario" },
        { text: "Valor Total", value: "valorTotal" },
        { text: "Parecer", value: "actions" },
        { text: "Status", value: "status" },
      ],
    },
    solicitacaoEstadoInicial: {
      id: undefined,
      codigoMec: "",
      ano: "",
      cnpjUex: "",
      valorRepasse: 0,
      valorCusteio: 0,
      valorCapital: 0,
      saldoCusteio: 0,
      saldoCapital: 0,
      status: "",
      dataEnvio: "",
      statusId: 0,
      recursoCusteio: {
        materiais: [],
      },
      recursoCapital: {
        materiais: [],
      },
      validaParaEnviar: false,
    },
    solicitacao: {
      id: undefined,
      codigoMec: 0,
      ano: "",
      cnpjUex: "",
      valorRepasse: 0,
      valorCusteio: 0,
      valorCapital: 0,
      saldoCusteio: 0,
      saldoCapital: 0,
      status: "",
      dataEnvio: "",
      statusId: 0,
      recursoCusteio: {
        materiais: [],
      },
      recursoCapital: {
        materiais: [],
      },
      validaParaEnviar: false,
    },
    recursoItemEstadoInicial: {
      recursoId: 0,
      recursoGestorId: undefined,
      parecer: false,
      justificativa: "",
      status: "",
    },
    recursoItem: {
      recursoId: 0,
      recursoGestorId: undefined,
      parecer: false,
      justificativa: "",
      status: "",
    },
    tituloPdf: "Dados da solicitação",
  }),
  beforeMount() {
    this.showPageLoading();
    this.verificarMenuDoUsuario();
  },
  mounted() {
    this.getListaDeSolicitacoesGre();
  },
  methods: {
    getListaDeSolicitacoesGre() {
      this.grid.loading = true;
      this.showPageLoading();
      this.$api()
        .get(configurations.api.admin.paf.getObterListaDeSolicitacaoGreUri, {
          params: {
            nomeDeUsuario: this.user.username,
          },
        })
        .then(({ data }) => {
          if (data && data.dados) {
            this.grid.data = data.dados;
          }
        })
        .catch(({ response }) => {
          this.verificarSeAcessoAutorizado(response);
          this.exibirMensagemDeRetorno(
            response,
            "Não foram encontradas solicitações para esse usuário."
          );
        })
        .then(() => {
          this.grid.loading = false;
          this.hidePageLoading();
        });
    },
    salvarParecerDaGre() {
      this.showPageLoading();
      this.recursoItem.nomeDeUsuario = this.user.username;
      this.$api()
        .post(
          configurations.api.admin.paf.getEnviarParecerGreUri,
          this.recursoItem
        )
        .then(({ data }) => {
          this.processarListaDeItensDoRecurso(data);
          this.fecharDialogoParecerDaGre();
        })
        .catch(({ response }) => {
          this.verificarSeAcessoAutorizado(response);
          this.exibirMensagemDeRetorno(
            response,
            "Não foi possível salvar o item."
          );
        })
        .then(() => {
          this.grid.loading = false;
          this.hidePageLoading();
        });
    },
    retornarSolicitacaoEscola() {
      const comando = {
        solicitacaoId: this.solicitacao.id,
        nomeDeUsuario: this.user.username,
      };
      if (!this.validarSePodeSalvarSolicitacaoGre()) {
        this.notifyWarning(
          "Você precisa validar todos os recursos antes de salvar a solicitação"
        );
      } else {
        this.$api()
          .post(
            configurations.api.admin.paf.getEnviarSolicitacaoValidadaUri,
            comando
          )
          .then(({ data }) => {
            if (data && data.sucesso) {
              this.notifySuccess("Solicitação salva com sucesso!");
            }
          })
          .catch(({ response }) => {
            this.verificarSeAcessoAutorizado(response);
            this.exibirMensagemDeRetorno(
              response,
              "Não foi possível salvar a solicitação."
            );
          })
          .then(() => {
            this.grid.loading = false;
            this.hidePageLoading();
            this.fecharDialogoVisualizarSolicitacao();
          });
      }
    },
    processarListaDeItensDoRecurso(data) {
      if (data && data.itensDeRecurso) {
        this.solicitacao.itensDeRecurso = data.itensDeRecurso;
        this.atribuirListaDeRecursoItens(data.itensDeRecurso);
        this.notifySuccess("Recurso alterado com sucesso!");
      }
    },
    visualizarSolicitacao(item) {
      this.solicitacao = this.converterEmSolicitacao(this.solicitacao, item);
      this.dialogoVisualizarSolicitacao = true;
    },
    parecerRecursoItem(item) {
      this.recursoItem.solicitacaoId = this.solicitacao.id;
      this.recursoItem.recursoId = item.recursoId;
      this.recursoItem.justificativa = item.justificativa;
      this.recursoItem.recursoGestorId = item.recursoGestorId;
      this.recursoItem.parecer = item.parecer;
      this.dialogoParecerDoGerenteRegional = true;
    },
    fecharDialogoVisualizarSolicitacao() {
      this.getListaDeSolicitacoesGre();
      this.dialogoVisualizarSolicitacao = false;
    },
    fecharDialogoParecerDaGre() {
      this.getListaDeSolicitacoesGre();
      this.dialogoParecerDoGerenteRegional = false;
    },
    filter() {
      if (this.grid.filters.filters || this.grid.filters.cnpjUex) {
        // Filtrar
        this.grid.filtered = true;
      } else {
        // Limpar filtro
        this.grid.filtered = false;
      }
      this.mobileFilter = false;
    },
    verificarSeAcessoAutorizado(response) {
      if (response && response.status == 401)
        this.$router.push({ path: "/login" });
    },
    verificarMenuDoUsuario() {
      if (this.$store.getters[`${AUTH_NAMESPACE}/${MENU}`].filter(m => m.url === "/admin/paf-gre").length === 0) {
        this.$router.push({ path: "/login" });
      }
    },
    exibirMensagemDeRetorno(response, msg) {
      if (response && response.data.mensagem) {
        this.notifyWarning(response.data.mensagem);
      } else {
        this.notifyWarning(msg);
      }
    },
    converterEmSolicitacao(solicitacao, item) {
      solicitacao.cnpjUex = item.cnpjUex;
      solicitacao.ano = item.ano;
      solicitacao.codigoMec = item.codigoMec;
      solicitacao.id = item.id;
      solicitacao.saldoCapital = item.saldoCapital;
      solicitacao.saldoCusteio = item.saldoCusteio;
      solicitacao.valorRepasse = item.valorRepasse;
      solicitacao.valorCusteio = item.valorCusteio;
      solicitacao.valorCapital = item.valorCapital;
      solicitacao.status = item.status;
      solicitacao.statusId = item.statusId;
      solicitacao.dataEnvio = item.dataEnvio;
      if (this.solicitacao.itensDeRecurso)
        this.atribuirListaDeRecursoItens(solicitacao.itensDeRecurso);
      else this.atribuirListaDeRecursoItens(item.itensDeRecurso);
      return solicitacao;
    },
    atribuirListaDeRecursoItens(lista) {
      this.solicitacao.recursoCusteio.materiais = lista.filter(
        (item) => item.codTipo === 1
      );
      this.solicitacao.recursoCapital.materiais = lista.filter(
        (item) => item.codTipo === 2
      );
    },
    definirIconStatusItem(item) {
      if (item.status.toLowerCase() === "aprovado")
        return "mdi-checkbox-marked";
      if (item.status.toLowerCase() === "negado")
        return "mdi-close-box-outline";
      return "";
    },
    definirCorIconStatusItem(item) {
      if (item.status.toLowerCase() === "aprovado") return "green";
      if (item.status.toLowerCase() === "negado") return "red";
      return "";
    },
    validarSePodeSalvarSolicitacaoGre() {
      const itens = this.solicitacao.recursoCusteio.materiais.concat(
        this.solicitacao.recursoCapital.materiais
      );
      return this.verificarSeTodosRecursosForamValidados(itens);
    },
    verificarSeTodosRecursosForamValidados(itens) {
      return itens.every((item) => item.status.toLowerCase() !== "");
    },
    async preSolicitacaoDownload(item) {
      this.solicitacao = this.converterEmSolicitacao(this.solicitacao, item);
    },
    async downloadComprovante(item) {
      await this.preSolicitacaoDownload(item);
      const conteudo = this.$refs.comprovante.getHtml();
      this.showPageLoading();
      this.$api()
        .post(configurations.api.admin.servicos.postHtmlParaPdfUri, {
          html: conteudo,
        })
        .then(({ data }) => {
          const linkSource = `data:application/pdf;base64,${data.pdf}`;
          const downloadLink = document.createElement("a");
          const fileName = `solicitacao-comprovante.pdf`;
          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
        })
        .catch(({ response }) => {
          if (response && response.data) {
            this.notifyError(response.data.mensagem);
          } else {
            this.notifyError(
              "Não conseguimos nos comunicar com o servidor da aplicação."
            );
          }
        })
        .then(() => {
          this.hidePageLoading();
        });
    },
    imprimirPdfSolicitacao() {
      this.tituloPdf = "Dados da solicitação";
      const conteudo = this.$refs.comprovante.getHtml();
      let win = window.open();
      win.document.write(conteudo);
      win.print();
      win.close();
    }
  },
  computed: {
    ...postLoginGetters([USER]),
    filteredItems() {
      return this.grid.data.filter((i) => {
        return i.cnpjUex.includes(this.grid.filters.cnpjUex);
      });
    },
    valorTotalAplicadoEmCusteio() {
      return this.solicitacao.recursoCusteio.materiais.reduce(
        (acc, value) => acc + value.valorTotal,
        0
      );
    },
    valorTotalAplicadoEmCapital() {
      return this.solicitacao.recursoCapital.materiais.reduce(
        (acc, value) => acc + value.valorTotal,
        0
      );
    },
    verificarSePodeAprovarESalvarSolicitacao() {
      return this.solicitacao.status.toLowerCase() === "enviado" || this.solicitacao.status.toLowerCase() === "em análise";
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-data-table {
  .col-email {
    max-width: 200px;
  }

  .col-address {
    max-width: 200px;
  }
}

.shorten {
  max-width: calc(100% - 260px);
}

.hidden {
  visibility: hidden;
  display: none;
}

.text-green input {
  color: green !important;
}

.hide-table {
  display: none;
}
</style>
